import * as React from 'react';

import Link from 'next/link';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { useRouter } from 'next/router';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useTranslation } from 'react-i18next';
import { useLoginText } from 'hooks/useLoginText';
import styles from './BookingWidgetHeader.module.css';

export const MobileLoginItem = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { accessToken } = useCustomerToken();
  const { useApiKeyInPaths, apiKey } = React.useContext(ApiKeyContext);

  const loginText = useLoginText();

  const getPageUrl = (pageName: string) => {
    return `${useApiKeyInPaths ? `/${apiKey}` : ''}${pageName}`;
  };

  const loginHandler = () => {
    router.push(getPageUrl('/me/signin'));
  };

  return (
    <li className={styles['c-header__nav__list__item']}>
      {accessToken ? (
        <Link href={getPageUrl('/me/top')} legacyBehavior>
          {t('My Page')}
        </Link>
      ) : (
        <a onClick={loginHandler}>{loginText}</a>
      )}
    </li>
  );
};
